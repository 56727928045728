import {usePage} from '@inertiajs/react';
import {memo} from 'react';

const ApplicationLogo = (props) => {
    const {url_brand} = usePage().props;
    let image = '/images/logos/eureka-v2.jpg';
    let alt = 'Logo Eureka V2';
    switch (url_brand) {
        case 'ya-k':
            image = '/images/logos/ya-k.png';
            alt = 'Logo Ya K Construire';
            break;
        case 'couleur-villas':
            image = '/images/logos/couleur-villas.png';
            alt = 'Logo Couleur Villas';
            break;
    }
    return <img className={'app-logo'} src={image} alt={alt} {...props} />;
};

export default memo(ApplicationLogo);
