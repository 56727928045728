const components_utils = {
    MuiLink: {
        styleOverrides: {
            root: {
                display: 'inline-block',
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                lineHeight: 1.5,
                textTransform: 'none',
            },
            sizeLarge: {
                padding: '8px 16px',
            },
            contained: {
                color: 'white',
            },
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                '&[readonly]': {
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    'input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        '&:before': {
                            borderBottom: 'none',
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottom: 'none',
                        },
                    },
                },
            },
        },
    },
};

export default components_utils;
