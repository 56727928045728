import ApplicationLogo from '@/Components/ApplicationLogo.jsx';
import ImagesStyle from '@/Styles/images.js';
import {Box, styled, Typography} from '@mui/material';
import React, {memo} from 'react';

const YaKHeaderHeadings = () => {
    const styles = {
        headings_icon: {
            marginLeft: '30px',
            marginRight: '30px',
            width: 116,
            height: 87,
        },
        headings: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'nowrap',
            flex: 1,
            marginTop: '48px',
            whiteSpace: 'nowrap',
        },
    };

    return (
        <Box sx={styles.headings}>
            <Box sx={styles.headings_icon}>
                <img src={'/theme/ya-k/images/icons/menu-maison.png'} alt="" style={ImagesStyle.contain_center} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                <Typography variant="fancy_subtitle1" sx={{display: 'inline-block'}}>
                    configurez&nbsp;
                </Typography>
                <Typography variant="subtitle2" sx={{display: 'inline-block'}}>
                    votre futur projet de construction
                </Typography>
            </Box>
        </Box>
    );
};
export default memo(YaKHeaderHeadings);
