const palette_utils = {
    eureka_primary: {
        main: '#118798',
    },
    ya_k_primary: {
        main: '#f5cc00',
    },
    couleur_villas_primary: {
        main: '#e2003b',
    },
    text: {
        dark: '#262626',
        main: '#374151',
        light: '#58616E',
        contrastText: '#fffff',
    },
    border: {
        main: '#E1E1E1',
    },
    background: {
        dark: '#262626',
        main: '#C4C4C4',
        light: '#F5F5F5',
    },
    white: {
        dark: '#F5F5F5',
        main: '#FFFFFF',
        light: '#FFFFFF',
        contrastText: '#262626',
    },
};

export default palette_utils;
