import palette_utils from '@/Themes/utils/palette_utils.js';

const palette = {
    primary: palette_utils.ya_k_primary,
    white: palette_utils.white,
    text: palette_utils.text,
    background: palette_utils.background,
    border: palette_utils.border,
};

export default palette;
