import {Alert, Snackbar} from '@mui/material';
import React, {createContext, useCallback, useContext, useState} from 'react';

const NotificationsContext = createContext(null);

const NotificationsProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [config, setConfig] = useState({});

    const {variant, severity, ...options} = config;

    const showNotifications = useCallback((message, options = {}) => {
        setMessage(message);
        setOpen(true);
        setConfig(options);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <NotificationsContext.Provider value={{showNotifications}}>
            {children}
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                {...options}
                open={open}
                onClose={handleClose}
            >
                {open ? (
                    severity ? (
                        <Alert
                            variant={variant ?? 'filled'}
                            severity={severity}
                            sx={{width: '100%'}}
                            onClose={handleClose}
                        >
                            {message}
                        </Alert>
                    ) : (
                        message
                    )
                ) : null}
            </Snackbar>
        </NotificationsContext.Provider>
    );
};

const useNotifications = () => {
    return useContext(NotificationsContext);
};

export {useNotifications, NotificationsProvider};
