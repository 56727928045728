import './bootstrap';
import '../css/app.css';
import {NotificationsProvider} from '@/Components/Providers/NotificationsProvider.jsx';
import MainLayout from '@/Layouts/MainLayout.jsx';
import {loadFont} from '@/Scripts/Common/fonts.js';
import {default as couleur_villas_theme} from '@/Themes/couleur-villas/theme.js';
import {default as eureka_theme} from '@/Themes/eureka/theme.js';
import {default as ya_k_theme} from '@/Themes/ya-k/theme.js';
import {createInertiaApp} from '@inertiajs/react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {createRoot} from 'react-dom/client';

const appName = import.meta.env.VITE_APP_NAME || 'Eureka V2';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        const page = await resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'));
        page.default.layout ??= (page) => <MainLayout page_name={name}>{page}</MainLayout>;
        return page;
    },
    setup({el, App, props}) {
        const root = createRoot(el);
        const {url_brand} = props.initialPage.props;
        const app_dom = document.querySelector('html');
        let app_classes = 'theme-' + (url_brand ?? 'eureka');

        let theme = eureka_theme;
        let fonts = [
            'https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap',
            'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ];
        switch (url_brand) {
            case 'ya-k':
                theme = ya_k_theme;
                fonts = [];
                break;
            case 'couleur-villas':
                theme = couleur_villas_theme;
                break;
        }
        if (app_dom) {
            app_dom.classList.add(app_classes);
        }

        fonts.forEach((font_url) => {
            loadFont(font_url);
        });

        root.render(
            <>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <NotificationsProvider>
                        <App {...props} />
                    </NotificationsProvider>
                </ThemeProvider>
            </>,
        );
    },
    progress: {
        color: '#4B5563',
    },
});
