import components_utils from '@/Themes/utils/components_utils.js';

const components = {
    MuiLink: {
        ...components_utils.MuiLink,
    },
    MuiButton: {
        styleOverrides: {
            root: {
                lineHeight: 1.25,
                textTransform: 'none',
                padding: '0 1.5rem',
                minHeight: '60px',
                boxShadow: 'none',
            },
            contained: {
                color: 'black',
            },
        },
    },
    MuiTextField: {
        ...components_utils.MuiTextField,
    },
};

export default components;
