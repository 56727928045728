import ApplicationLogo from '@/Components/ApplicationLogo.jsx';
import InertiaButton from '@/Components/Common/InertiaButton.jsx';
import YaKHeaderHeadings from '@/Components/Layouts/Themed/YaKHeaderHeadings.jsx';
import {Link as InertiaLink} from '@inertiajs/react';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Container, IconButton, Menu, MenuItem, styled, Toolbar, Typography} from '@mui/material';
import React, {memo, useMemo, useState} from 'react';

const HeaderLogo = styled(ApplicationLogo)({
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
});

const getNavItems = (user) => {
    let result = [
        {
            name: 'Accueil',
            color: 'text',
            href: route('configurator.index'),
        },
    ];
    if (user) {
        result.push({
            name: 'Tableau de bord',
            color: 'text',
            href: route('dashboard.index'),
        });
        result.push({
            name: 'Votre compte',
            color: 'text',
            href: route('profile.edit'),
        });
        result.push({
            name: 'Déconnexion',
            variant: 'contained',
            method: 'POST',
            href: route('logout'),
        });
    } else {
        result.push({
            name: 'Connexion',
            variant: 'contained',
            href: route('login'),
        });
    }
    return result;
};

const Header = ({brand, user}) => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const nav_items = useMemo(() => getNavItems(user), [user]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const styles = {
        app_bar: {
            border: '1px solid rgba(0, 0, 0, 0.1)',
        },
        container: (theme) => ({
            maxWidth: theme.breakpoints.xl,
        }),
        toolbar: {
            justifyContent: 'space-between',
        },
        desktop_right_block: {
            display: {xs: 'none', md: 'flex'},
        },
        desktop_nav: {
            display: 'flex',
            gap: 2,
        },
        desktop_nav_item: {
            my: 2,
        },
        desktop_nav_button: {
            display: 'block',
            textDecoration: 'none',
        },
        logo: {
            display: 'inline-block',
            flexGrow: {xs: 1, md: 0},
            width: '132px',
            height: '35px',
        },
        mobile_menu_icon: {},
    };

    switch (brand) {
        case 'ya-k':
            styles.app_bar = {
                alignItems: 'stretch',
            };
            styles.container = (theme) => ({
                [theme.breakpoints.up('lg')]: {
                    width: '75vw',
                    maxWidth: '1920px !important',
                    padding: '0 64px !important',
                    gap: 6,
                },
            });
            styles.toolbar = (theme) => ({
                ...styles.toolbar,
                justifyContent: 'space-between',
                alignItems: 'stretch',
                gap: 3,
                minHeight: '175px !important',
                [theme.breakpoints.down('lg')]: {
                    gap: 6,
                },
            });
            styles.desktop_right_block = {
                ...styles.desktop_right_block,
                position: 'relative',
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
                flex: 1,
                boxShadow: '-20px 7px 20px rgba(0, 0, 0, .15)',
            };
            styles.desktop_nav = {
                ...styles.desktop_nav,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flex: 0,
                gap: 0,
            };
            styles.desktop_nav_item = {
                textTransform: 'lowercase',
                padding: '0 62px',
            };
            styles.logo = {
                display: 'inline-block',
                flexGrow: {xs: 1, md: 0},
                marginTop: '48px',
                width: '193px',
                height: '107px',
                flex: '0 0 auto',
            };
            styles.mobile_menu_icon = {
                fontSize: '2rem',
            };
            break;
        case 'couleur-villas':
            break;
    }

    return (
        <>
            <AppBar position={brand === 'ya-k' ? 'static' : 'fixed'} color="white" elevation={0} sx={styles.app_bar}>
                <Container sx={styles.container}>
                    <Toolbar disableGutters sx={styles.toolbar}>
                        <InertiaLink href={route('configurator.index')} style={styles.logo}>
                            <HeaderLogo />
                        </InertiaLink>
                        <Box sx={{display: {xs: 'flex', md: 'none'}, alignItems: 'center'}}>
                            <IconButton
                                size="large"
                                aria-label="Menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon sx={styles.mobile_menu_icon} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{display: {xs: 'block', md: 'none'}}}
                            >
                                {nav_items.map(({name, href}) => (
                                    <MenuItem key={'mobile-' + name} onClick={handleCloseNavMenu}>
                                        <Typography sx={{textAlign: 'center'}}>{name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={styles.desktop_right_block}>
                            {brand === 'ya-k' && <YaKHeaderHeadings />}
                            <Box sx={styles.desktop_nav}>
                                {nav_items.map(({name, variant, color, method, href}) => (
                                    <InertiaButton
                                        key={name}
                                        method={method}
                                        href={href}
                                        style={styles.desktop_nav_button}
                                        variant={variant}
                                        color={color}
                                        size="large"
                                        sx={styles.desktop_nav_item}
                                    >
                                        {name}
                                    </InertiaButton>
                                ))}
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};
export default memo(Header);
