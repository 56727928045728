const typography_utils = {
    fonts: {
        arial: {
            regular: {
                fontFamily: "'Arial', 'sans-serif'",
                fontWeight: 'normal',
            },
            bold: {
                fontFamily: "'Arial', 'sans-serif'",
                fontWeight: 'bold',
            },
            black: {
                fontFamily: "'Arial', 'sans-serif'",
                fontWeight: 800,
            },
        },
        fraunces: {
            regular: {
                fontFamily: "'Fraunces', 'sans-serif'",
                fontWeight: 400,
            },
            semibold: {
                fontFamily: "'Fraunces', 'sans-serif'",
                fontWeight: 600,
            },
            bold: {
                fontFamily: "'Fraunces', 'sans-serif'",
                fontWeight: 700,
            },
        },
        poppins: {
            light: {
                fontFamily: "'Poppins', 'sans-serif'",
                fontWeight: 300,
            },
            regular: {
                fontFamily: "'Poppins', 'sans-serif'",
                fontWeight: 400,
            },
            semibold: {
                fontFamily: "'Poppins', 'sans-serif'",
                fontWeight: 600,
            },
            bold: {
                fontFamily: "'Poppins', 'sans-serif'",
                fontWeight: 700,
            },
        },
    },
    sizes: {
        h1: {
            fontSize: '4rem',
            lineHeight: 1.25,
        },
        h2: {
            fontSize: '3rem',
            lineHeight: 1.25,
        },
        h3: {
            fontSize: '2.5rem',
            lineHeight: 1.25,
        },
        h4: {
            fontSize: '2.25rem',
            lineHeight: 1.25,
        },
        h5: {
            fontSize: '2rem',
            lineHeight: 1.25,
        },
        subtitle1: {
            fontSize: '1.5rem',
            lineHeight: 1.5,
        },
        subtitle2: {
            fontSize: '1.25rem',
            lineHeight: 1.5,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.875rem',
            lineHeight: 1.5,
        },
        caption: {
            fontSize: '0.75rem',
            lineHeight: 1.5,
        },
        mobile: {
            h1: {
                //fontSize: '4rem',
                fontSize: '3.2rem',
                lineHeight: 1.25,
            },
            h2: {
                //fontSize: '3rem',
                fontSize: '2.4rem',
                lineHeight: 1.25,
            },
            h3: {
                //fontSize: '2.5rem',
                fontSize: '2rem',
                lineHeight: 1.25,
            },
            h4: {
                //fontSize: '2.25rem',
                fontSize: '1.8rem',
                lineHeight: 1.25,
            },
            h5: {
                //fontSize: '2rem',
                fontSize: '1.6rem',
                lineHeight: 1.25,
            },
            subtitle1: {
                //fontSize: '1.5rem',
                fontSize: '1.2rem',
                lineHeight: 1.5,
            },
            subtitle2: {
                //fontSize: '1.25rem',
                fontSize: '1rem',
                lineHeight: 1.5,
            },
        },
    },
};

export default typography_utils;
