import breakpoints from '@/Themes/couleur-villas/breakpoints.js';
import palette_utils from '@/Themes/utils/palette_utils.js';
import typography_utils from '@/Themes/utils/typography_utils.js';

const typography = {
    display: 'inline-block',
    color: palette_utils.text.dark,
    letterSpacing: 0.25,
    fancy_h1: {
        ...typography_utils.fonts.fraunces.semibold,
        ...typography_utils.sizes.mobile.h1,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h1,
        },
    },
    fancy_h2: {
        ...typography_utils.fonts.fraunces.semibold,
        ...typography_utils.sizes.mobile.h2,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h2,
        },
    },
    fancy_h3: {
        ...typography_utils.fonts.fraunces.semibold,
        ...typography_utils.sizes.mobile.h3,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h3,
        },
    },
    fancy_h4: {
        ...typography_utils.fonts.fraunces.semibold,
        ...typography_utils.sizes.mobile.h4,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h4,
        },
    },
    fancy_h5: {
        ...typography_utils.fonts.fraunces.semibold,
        ...typography_utils.sizes.mobile.h5,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h5,
        },
    },
    fancy_subtitle1: {
        ...typography_utils.fonts.fraunces.regular,
        ...typography_utils.sizes.mobile.subtitle1,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.subtitle1,
        },
    },
    h1: {
        ...typography_utils.fonts.poppins.light,
        ...typography_utils.sizes.mobile.h1,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h1,
        },
    },
    h2: {
        ...typography_utils.fonts.poppins.light,
        ...typography_utils.sizes.mobile.h2,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h2,
        },
    },
    h3: {
        ...typography_utils.fonts.poppins.light,
        ...typography_utils.sizes.mobile.h3,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h3,
        },
    },
    h4: {
        ...typography_utils.fonts.poppins.light,
        ...typography_utils.sizes.mobile.h4,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h4,
        },
    },
    h5: {
        ...typography_utils.fonts.poppins.light,
        ...typography_utils.sizes.mobile.h5,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.h5,
        },
    },
    subtitle1: {
        ...typography_utils.fonts.poppins.regular,
        ...typography_utils.sizes.mobile.subtitle1,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.subtitle1,
        },
    },
    subtitle2: {
        ...typography_utils.fonts.poppins.light,
        ...typography_utils.sizes.mobile.subtitle2,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
            ...typography_utils.sizes.subtitle2,
        },
    },
    body1: {
        ...typography_utils.fonts.poppins.regular,
        ...typography_utils.sizes.body1,
        letterSpacing: 'normal',
    },
    body2: {
        ...typography_utils.fonts.poppins.light,
        ...typography_utils.sizes.body2,
        letterSpacing: 'normal',
    },
    caption: {
        ...typography_utils.fonts.poppins.light,
        ...typography_utils.sizes.caption,
        letterSpacing: 'normal',
    },
};

export default typography;
