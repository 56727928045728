import breakpoints from '@/Themes/couleur-villas/breakpoints.js';
import components from '@/Themes/couleur-villas/components.js';
import palette from '@/Themes/couleur-villas/palette.js';
import typography from '@/Themes/couleur-villas/typography.js';
import utils from '@/Themes/utils/utils.js';
import {createTheme} from '@mui/material';
import {frFR} from '@mui/material/locale';

const theme = createTheme(
    {
        ...utils,
        breakpoints: {...breakpoints},
        palette: {...palette},
        typography: {...typography},
        components: {...components},
    },
    frFR,
);

export default theme;
