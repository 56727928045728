export const loadFont = (font_url) => {
    const existing_link = document.querySelector(`link[rel="stylesheet"][href="${font_url}"]`);

    if (!existing_link) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = font_url;
        link.setAttribute('data-theme-font', 'true');
        document.head.appendChild(link);
    }
};
