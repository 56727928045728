import components_utils from '@/Themes/utils/components_utils.js';

const components = {
    MuiButton: {
        ...components_utils.MuiButton,
    },
    MuiTextField: {
        ...components_utils.MuiTextField,
    },
};

export default components;
